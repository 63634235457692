<template>
  <van-tabbar route active-color="#2F9E73">
    <van-tabbar-item icon="wap-home" to="/pah/">首页</van-tabbar-item>
    <van-tabbar-item icon="graphic" to="/pah/dev">设备</van-tabbar-item>
    <van-tabbar-item icon="setting" to="/pah/about">关于</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'tabber',
}
</script>
